import React, { useContext } from "react"
import { graphql } from "gatsby"
import GoogleMapReact from 'google-map-react'


import SEO from "components/seo"

import Form from "components/form"
import MiniHero from "components/miniHero"

import { SUBMIT_FORM } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { MainContext } from "../context/context"

const isClient = typeof window !== 'undefined'

export const GoogleMap = ({
	lat,
	lng,
	googleMapsApiKey	
}) => {
	return (
		<section className="google-map">
			<div className="map">
				{isClient && (
					<GoogleMapReact
						bootstrapURLKeys={{ key: googleMapsApiKey }}
						defaultCenter={[lat, lng]}
						defaultZoom={14}
						options={{
							zoomControl: false,
							fullscreenControl: false
						}}
					>
						<div
							className="marker"
							lat={lat}
							lng={lng}
						/>
					</GoogleMapReact>
				)}
			</div>
		</section>
	);
}

export default ({ data }) => {
	const {
		title,
		excerpt,
		contactFields,
		flexibleContent
	} = data.siteFields.page

	return (
		<>
			<SEO title='Contact' description='Strikeforce - Contact us.' />
			<div className="contact">
				<MiniHero 
                    title={title} 
                    preTitle="&nbsp;"
                />
				<div className="container">
					<div className='contact-form'>
						<Form id={flexibleContent.form.id} />
					</div>
					<div className='contact-offices'>
						<h2 dangerouslySetInnerHTML={{ __html: contactFields.officesHeading }} />
						<ul>
							{ contactFields.offices.map((row, index) => {
								return (
									<li key={index}>
										<GoogleMap 
											googleMapsApiKey="AIzaSyCkQv9Z5hzzZpri3lqDCXVPDPda4N6Z81o" 
											lat={row.location.latitude} 
											lng={row.location.longitude} 
										/>
										<div className='contact-office-text'>
											<h2 dangerouslySetInnerHTML={{ __html: row.title }} />
											{ row.national &&
												<h4 dangerouslySetInnerHTML={{ __html: row.national ? 'National' : '' }} />
											}
											<div className='contact-office-location'>
												<i className="fe fe-navigation"></i>
												<span dangerouslySetInnerHTML={{ __html: row.displayLocation }} />
											</div>
											{ row.phone &&
												<div className='contact-office-phone'>
													<i className="fe fe-phone"></i>
													<a href={'tel:' + row.phone} dangerouslySetInnerHTML={{ __html: row.phone }} />
												</div>
											}
										</div>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export const query = graphql`
  query($databaseId: ID!) {
    siteFields {
      page(id: $databaseId, idType: DATABASE_ID) {
        databaseId
        title
        date
		flexibleContent {
			form {
				... on WPGraphQL_Form {
					id
				}
			}
		}
        contactFields {
            officesHeading
            offices {
                title
                displayLocation
                location {
                    latitude
                    longitude
                }
                phone
                national
            }
        }
      }
    }
  }
`
